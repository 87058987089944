const binding = { modules: {}, dataActions: {} };

    (binding.modules['only-c-content-block'] = {
        c: () => require('partner/modules/only-c-content-block/only-c-content-block.tsx'),
        $type: 'contentModule',
        da: [],
        
        iNM: false,
        ns: '__local__',
        n: 'only-c-content-block',
        p: '__local__',
        
        pdp: '',
        
        
        md: 'src/modules/only-c-content-block'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|modules|only-c-content-block|only-c-content-block'] = {
                c: () => require('partner/modules/only-c-content-block/only-c-content-block.view.tsx'),
                cn: '__local__-__local__-only-c-content-block'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };